export function useScrollbarWidth() {
  const [scrollbarWidth, setScrollbarWidth] = React.useState(0)

  React.useEffect(
    () => {
      const element = document.createElement('div')
      element.style.width = '100px'
      element.style.height = '100px'
      element.style.overflow = 'scroll'
      element.style.visibility = 'hidden'
      element.setAttribute('aria-hidden', 'true')

      document.body.appendChild(element)
      setScrollbarWidth(element.offsetWidth - element.clientWidth)
      document.body.removeChild(element)
    },
    []
  )

  return scrollbarWidth
}
