import { useTranslate } from '/machinery/I18n'
import { useMediaQuery } from '/machinery/useCachingMediaQuery'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { ShareButton } from '/features/buildingBlocks/ShareButton'
import { ImageCover } from '/features/buildingBlocks/Image'
import { CardImage } from '/features/regionArticles/cards/CardImage'
import { HeadingGroupSnackables } from '/features/buildingBlocks/HeadingGroup'
import { CardSnackablesContentIntro } from '/features/regionArticles/cards/CardSnackables'

import gradientOverlayStyles from '/features/buildingBlocks/gradientOverlay.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './ArticleHero.css'

export function ArticleHeroDefault({ hero, region = '', showShareButton = false, layoutClassName = undefined }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)
  const { image, imageSecondary } = hero

  return (
    <ArticleHeroBase
      shareImage={image}
      className={cx(styles.componentDefault, gradientOverlayStyles.gradientOverlayBlack, layoutClassName)}
      {...{ showShareButton, region }}
    >
      <>
        {image && (
          <div inert={!isViewportMd && 'true'} className={styles.mobileImage}>
            <ImageCover
              aspectRatio={9 / 16}
              sizes='100vw'
              imgProps={{ fetchpriority: 'high' }}
              layoutClassName={styles.imageLayout}
              {...{ image }}
            />
          </div>
        )}
        {(image || imageSecondary) && (
          <div inert={isViewportMd && 'true'} className={styles.desktopImage}>
            <ImageCover
              image={imageSecondary ?? image}
              aspectRatio={16 / 9}
              sizes='100vw'
              imgProps={{ fetchpriority: 'high' }}
              layoutClassName={styles.imageLayout}
            />
          </div>
        )}
      </>
    </ArticleHeroBase>
  )
}

export function ArticleHeroSingleSnackable({ heroImage, title, rubric, showShareButton = false, layoutClassName = undefined }) {
  return (
    <ArticleHeroBase shareImage={heroImage} className={cx(styles.componentSingleSnackable, layoutClassName)} {...{ showShareButton }}>
      <ContainerLg layoutClassName={styles.containerLayout}>
        <div className={styles.singleSnackableContentWrapper}>
          <div className={styles.singleSnackableContentContainer}>
            {heroImage && <CardImage image={heroImage} aspectRatio={4 / 5} layoutClassName={styles.imageLayout} />}
            <div className={styles.headingContainer}>
              <HeadingGroupSnackables subtitle={rubric} {...{ title }} />
            </div>
          </div>
        </div>
      </ContainerLg>
    </ArticleHeroBase>
  )
}

export function ArticleHeroSnackables({ heroImage, title, rubric, articles, showShareButton = false, layoutClassName = undefined }) {
  return (
    <ArticleHeroBase shareImage={heroImage} className={cx(styles.componentSnackables, layoutClassName)} {...{ showShareButton }}>
      <CardSnackablesContentIntro layoutClassName={styles.snackablesCardLayout} {...{ title, rubric, articles }} />
    </ArticleHeroBase>
  )
}

function ArticleHeroBase({ children, className, shareImage, region = '', showShareButton = false }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      {children}
      {showShareButton && shareImage && (
        <ArticleShareButton image={shareImage} layoutClassName={styles.shareButtonLayout} {...{ region }} />
      )}
    </div>
  )
}

function ArticleShareButton({ image, region, layoutClassName = undefined }) {
  const { __ } = useTranslate()

  return (
    <ShareButton
      title={`Rabo &Co - ${region}`}
      type='region'
      shareText={__`share-share-text`}
      text={__`share-text`}
      {...{ image, layoutClassName }}
    />
  )
}
