import { useQueryString } from '@kaliber/sanity-routing/queryString'

import { useTranslate } from '/machinery/I18n'

import { TagButton } from '/features/buildingBlocks/TagButton'

import styles from './Tags.css'

export function Tags({ tags, onActiveChange, contentIsCentered }) {
  return <TagsBase {...{ tags, onActiveChange, contentIsCentered }} />
}

export function TagsCanonical({ tags, onActiveChange, contentIsCentered }) {
  return <TagsBase {...{ tags, onActiveChange, contentIsCentered }} />
}

function TagsBase({ tags, onActiveChange, contentIsCentered = false }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentBase, contentIsCentered && styles.isCentered)}>
      <p className={styles.tagsTitle}>{__`read-more-about-subjects`}</p>
      <ul className={styles.tagsList}>
        {tags.map((tag, index) => <Tag key={index} {...{ tag, index, onActiveChange }} />)}
      </ul>
    </div>
  )
}

function Tag({ tag, onActiveChange, index }) {
  const [_, setQueryString] = useQueryString()

  return (
    <li>
      <TagButton
        label={tag}
        buttonId={index}
        dataX='click-to-open-tag-related-articles'
        onClick={handleClick}
      />
    </li>
  )

  function handleClick() {
    onActiveChange(true)
    setQueryString(x => ({ ...x, tag }))
  }
}
