import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { animated } from '@react-spring/web'

import { routeMap } from '/routeMap'
import { useArticlesWithReadState } from '/domain/hooks/useArticlesWithReadState'
import { useScrollProgressionScalingCard } from '/machinery/useScrollProgressionEvents'
import { mergeRefs } from '/machinery/mergeRefs'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { useParams } from '/machinery/Params'
import { useClientConfig } from '/machinery/ClientConfig'

import { useDrawerGestures } from '/features/regionArticles/useDrawerGestures'
import { ContainerMd } from '/features/buildingBlocks/Container'
import { ReadProgression } from '/features/article/buildingBlocks/ReadProgression'
import { Menu } from '/features/pageOnly/Menu'
import { ArticleHeroDefault } from '/features/article/buildingBlocks/ArticleHero'
import { ArticleIntroDefault, ArticleIntroDefaultBlack, ArticleIntroGedicht, ArticleIntroSingleSnackable, ArticleIntroSnackables } from '/features/article/buildingBlocks/ArticleIntro'
import { AudioPlayerArticlePage } from '/features/gedicht/buildingBlocks/AudioPlayer'
import { ContentContainerDefault, ContentContainerCentered } from '/features/article/buildingBlocks/ContentContainer'
import { Tags } from '/features/article/buildingBlocks/Tags'
import { LoopingVideoAll } from '/features/article/pageOnly/LoopingVideo'
import { TagModal } from '/features/article/buildingBlocks/TagModal'
import { Credits } from '/features/article/buildingBlocks/Credits'
import { NextArticle } from '/features/article/buildingBlocks/NextArticle'
import { DeckToc } from '/features/regionArticles/DeckToc'
import { extractArticles } from '/features/regionArticles/extractArticles'
import { findCover } from '/features/regionArticles/findCover'
import { WoodwingContent, WoodwingContentItem, WoodwingKader } from '/features/Woodwing'
import { ImageCover } from '/features/buildingBlocks/Image'
import { HeadingGroup } from '/features/buildingBlocks/HeadingGroup'
import { ButtonLinkSecondary, ButtonIconWithLabel } from '/features/buildingBlocks/Button'
import { DrawerArticleLink } from '/features/regionArticles/deckToc/DrawerArticleLink'
import { Search } from '/features/regionArticles/Search'

import styles from './ArticlePage.css'

import chrevronLeft from '/images/icons/chevron-left.raw.svg'
import chrevronRight from '/images/icons/chevron-right.raw.svg'

export function ArticlePageDefaultWithoutContext({ doc }) {
  const renderers = getArticlePageDefaulRenderersWithoutContext({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageDefault({ doc, regionData, issues }) {
  const renderers = getArticlePageDefaulRenderers({ doc })
  return <ArticlePage {...{ doc, regionData, issues }} {...renderers} />
}

export function ArticlePageResearchWithoutContext({ doc }) {
  const renderers = getArticlePageResearchRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageResearch({ doc, regionData, issues }) {
  const renderers = getArticlePageResearchRenderers({ doc })
  return <ArticlePage {...{ doc, regionData, issues }} {...renderers} />
}

export function ArticlePageSingleSnackableWithoutContext({ doc }) {
  const renderers = getArticlePageSingleSnackableRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageSingleSnackable({ doc, regionData, issues }) {
  const renderers = getArticlePageSingleSnackableRenderers({ doc })
  return <ArticlePage {...{ doc, regionData, issues }} {...renderers} />
}

export function ArticlePageGedichtWithoutContext({ doc }) {
  const renderers = getArticlePageGedichtRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageGedicht({ doc, regionData, issues }) {
  const renderers = getArticlePageGedichtRenderers({ doc })
  return <ArticlePage {...{ doc, regionData, issues }} {...renderers} />
}

export function ArticlePageSnackablesWithoutContext({ doc }) {
  const renderers = getArticlePageSnackablesRenderers({ doc })
  return <ArticlePageWithoutContext {...{ doc }} {...renderers} />
}

export function ArticlePageSnackables({ doc, regionData, issues }) {
  const renderers = getArticlePageSnackablesRenderers({ doc })
  return <ArticlePage {...{ doc, regionData, issues }} {...renderers} />
}

function TagsArticlePage({ tags, contentIsCentered, isActive, onActiveChange, isCanonical = false }) {
  return (
    <>
      <Tags {...{ tags, onActiveChange, contentIsCentered }} />
      <TagModal {...{ tags, isActive, onActiveChange, isCanonical }} />
    </>
  )
}

function getArticlePageDefaulRenderers({ doc }) {
  return getDefaultRenderers({ doc })
}

function getArticlePageDefaulRenderersWithoutContext({ doc }) {
  return getDefaultRenderers({ doc })
}

function getArticlePageResearchRenderers({ doc }) {
  const { content, metadata: { rubric } } = doc
  const { hero } = content

  return {
    ...getDefaultRenderers({ doc }),

    renderIntroComponent: ({ layoutClassName, animation }) => (hero.title || hero.column) &&
      <ArticleIntroDefaultBlack
        rubric={rubric.name}
        style={animation}
        {...{ hero, layoutClassName }}
      />
  }
}

function getArticlePageSingleSnackableRenderers({ doc }) {
  const { content, metadata: { tags, rubric } } = doc
  const { hero, metadata: { credits } } = content
  const queryClient = new QueryClient()

  return {
    renderIntroComponent: ({ layoutClassName, animation }) => (hero.title || hero.column) &&
      <ArticleIntroSingleSnackable
        rubric={rubric.name}
        style={animation}
        {...{ hero, layoutClassName }}
      />,

    renderContentComponent: ({ sectionRef, animation, isCanonical, tagModalIsActive, onActiveTagModalChange, layoutClassName }) =>
      <QueryClientProvider client={queryClient}>
        <ArticlePageContentDefault contentItems={content.items} {...{ sectionRef, animation, isCanonical, tags, tagModalIsActive, onActiveTagModalChange, credits, layoutClassName }} />
      </QueryClientProvider>
  }
}

function getArticlePageSnackablesRenderers({ doc }) {
  const { content: { hero, articles }, metadata: { rubric } } = doc

  return {
    renderIntroComponent: ({ layoutClassName, animation }) => (hero.title || rubric.name) &&
      <ArticleIntroSnackables
        rubric={rubric.name}
        style={animation}
        {...{ hero, articles, layoutClassName }}
      />,

    renderContentComponent: ({ layoutClassName, animation, sectionRef }) =>
      <ArticlePageContentSnackables {...{ doc, layoutClassName, animation, sectionRef }} />
  }
}

function getArticlePageGedichtRenderers({ doc }) {
  const { content, metadata: { rubric } } = doc
  const { hero } = content

  return {
    ...getDefaultRenderers({ doc }),

    renderIntroComponent: ({ layoutClassName, animation }) => (hero.title || hero.column) &&
      <ArticleIntroGedicht
        rubric={rubric.name}
        style={animation}
        {...{ hero, layoutClassName }}
      />,

    renderContentComponent: ({ layoutClassName, animation, sectionRef }) =>
      <ArticlePageContentGedicht {...{ doc, layoutClassName, animation, sectionRef }} />
  }
}

function getDefaultRenderers({ doc }) {
  const { content, metadata: { tags, rubric } } = doc
  const { hero, metadata: { credits } } = content
  const queryClient = new QueryClient()

  return {
    renderHeroComponent: ({ layoutClassName }) => hero.image &&
      <ArticleHeroDefault showShareButton={rubric.slug !== 'kleingeld'} {...{ hero, layoutClassName }} />,

    renderIntroComponent: ({ onActiveTagModalChange, animation, layoutClassName }) => (hero.title || hero.column) &&
      <ArticleIntroDefault
        tagsClickable
        rubric={rubric.name}
        style={animation}
        {...{ hero, tags, onActiveTagModalChange, layoutClassName }}
      />,

    renderContentComponent: ({ sectionRef, animation, isCanonical, tagModalIsActive, onActiveTagModalChange, layoutClassName }) =>
      <QueryClientProvider client={queryClient}>
        <ArticlePageContentDefault contentItems={content.items} {...{ sectionRef, animation, isCanonical, tags, tagModalIsActive, onActiveTagModalChange, credits, layoutClassName }} />
      </QueryClientProvider>,
  }
}

function ArticlePage({ doc, regionData, issues, renderIntroComponent, renderContentComponent, renderHeroComponent = undefined }) {
  const { feedItems, region } = regionData
  const renderers = { renderHeroComponent, renderIntroComponent, renderContentComponent }

  const renderDeckItemComponent = ({ item, url }, idx) => <DrawerArticleLink key={idx} {...{ item, url }} />
  const { articles, handleArticleRead } = useArticlesWithReadState({ articles: extractArticles(feedItems) })

  const cover = findCover(feedItems)
  const [currentArticleIndex, articleCount] = [findArticleIndex(doc, articles) + 1, articles.length]

  const { bind, searchDrawer, tocDrawer } = useDrawerGestures({ dragGesturesEnabled: true })

  const [readProgressionTargetRef, setReadProgressionTargetRef] = React.useState(null)
  const { language, issue, bankcode, rubricSlug } = useParams()
  const backLink = `${routeMap.app.issue.region({ language, issue, bankcode })}#${rubricSlug}`

  return (
    <div className={cx(styles.component)}>
      <Menu
        finishedReading={currentArticleIndex === articleCount}
        layoutClassName={styles.menuLayout}
        {...{ tocDrawer, searchDrawer, currentArticleIndex, articleCount }}
      />

      <DeckToc
        coverImage={cover.content.hero.image}
        onClose={tocDrawer.handleClose}
        isOpen={tocDrawer.isOpen}
        layoutClassName={styles.deckTocLayout}
        {...{ region, articles, issues, renderDeckItemComponent }}
      />

      <Search
        onClose={searchDrawer.handleClose}
        isOpen={searchDrawer.isOpen}
        coverImage={cover.content.hero.image}
        layoutClassName={styles.searchLayout}
      />

      <ReadProgression
        scrollParentEnd={0.9}
        readTime={`${doc.metadata.readTime} min`}
        onTargetRefChange={x => setReadProgressionTargetRef(() => x)}
        layoutClassName={styles.readProgressionLayout}
        onEndReached={() => handleArticleRead(doc.id)}
        {...{ backLink }}
      />

      <div {...bind()}>
        <ArticlePageBodyWithNextArticle {...renderers} {...{ doc, regionData, readProgressionTargetRef, issues }} />
      </div>
    </div>
  )
}

function ArticlePageWithoutContext({ doc, renderIntroComponent, renderContentComponent, renderHeroComponent = undefined }) {
  const { language } = useParams()
  const backLink = routeMap.app.home({ language })
  const { label, handleReferredLinkClick } = useReferredLink()
  const [readProgressionTargetRef, setReadProgressionTargetRef] = React.useState(null)
  const { handleArticleRead } = useArticlesWithReadState()

  return (
    <div className={cx(styles.componentWithoutContext)}>
      <ReadProgression
        scrollParentEnd={1}
        readTime={`${doc.metadata.readTime} min`}
        onTargetRefChange={x => setReadProgressionTargetRef(() => x)}
        onEndReached={() => handleArticleRead(doc.id)}
        layoutClassName={styles.readProgressionLayout}
        {...{ backLink }}
      />

      <ArticlePageBody isCanonical {...{ doc, renderHeroComponent, renderIntroComponent, renderContentComponent, readProgressionTargetRef }}>
        <div className={styles.goBackContainer}>
          <ContainerMd>
            <ButtonIconWithLabel
              icon={chrevronLeft}
              onClick={handleReferredLinkClick}
              dataX='click-to-previous-page'
              {...{ label }}
            />
          </ContainerMd>
        </div>
      </ArticlePageBody>
    </div>
  )
}

function ArticlePageBodyWithNextArticle({
  doc,
  renderHeroComponent,
  renderIntroComponent,
  renderContentComponent,
  issues,
  regionData,
  readProgressionTargetRef,
}) {
  const { content: { hero } } = doc
  const { feedItems } = regionData
  const next = feedItems[findArticleIndex(doc, feedItems) + 1]

  return (
    <ArticlePageBodyBase
      isCanonical
      className={styles.componentBodyWithNextArticle}
      {...{
        hero,
        renderHeroComponent,
        renderIntroComponent,
        renderContentComponent,
        readProgressionTargetRef
      }}
    >
      {next && <NextArticle doc={next} layoutClassName={styles.nextArticleLayout} {...{ issues }} />}
    </ArticlePageBodyBase>
  )
}

function ArticlePageBody({
  doc,
  renderHeroComponent,
  renderIntroComponent,
  renderContentComponent,
  isCanonical,
  readProgressionTargetRef,
  children = undefined
}) {
  const { content: { hero } } = doc

  return (
    <ArticlePageBodyBase
      className={styles.componentBody}
      {...{
        hero,
        isCanonical,
        renderHeroComponent,
        renderIntroComponent,
        renderContentComponent,
        readProgressionTargetRef
      }}
    >
      {children}
    </ArticlePageBodyBase>
  )
}

function ArticlePageBodyBase({
  renderHeroComponent,
  renderIntroComponent,
  renderContentComponent,
  isCanonical,
  readProgressionTargetRef,
  className,
  children = undefined
}) {
  const { scrollProgressionScalingRef, scale, y } = useScrollProgressionScalingCard()
  const [tagModalIsActive, setTagModalIsActive] = React.useState(null)

  return (
    <div className={cx(styles.componentBodyBase, className)}>
      {/* // This wrapper is needed because of a layout issue. The container is given a height of 0
        // and in turn it will give its children a height of the viewport, check css comment for details */}
      {renderHeroComponent && (
        <div className={cx(styles.articleHeroContainer, styles.articleHeroContainerLayout)}>
          {renderHeroComponent({ layoutClassName: styles.articleHeroLayout })}
        </div>
      )}

      {renderIntroComponent({
        onActiveTagModalChange: setTagModalIsActive,
        animation: { scaleX: scale, y },
        layoutClassName: styles.articleIntroLayout
      })}


      {renderContentComponent({
        isCanonical,
        tagModalIsActive,
        onActiveTagModalChange: setTagModalIsActive,
        animation: { scaleX: scale },
        sectionRef: mergeRefs(readProgressionTargetRef, scrollProgressionScalingRef),
        layoutClassName: styles.scalingCardLayout,
      })}

      {children}
    </div>
  )
}

export function ArticlePageContent({
  sectionRef,
  animation,
  children,
  credits,
  isCanonical = false,
  tagModalIsActive = undefined,
  onActiveTagModalChange = undefined,
  tags = [],
  contentIsCentered = false,
  layoutClassName = undefined
}) {
  const ContentContainerComponent = contentIsCentered ? ContentContainerCentered : ContentContainerDefault

  return (
    <animated.section data-x='page-content' ref={sectionRef} style={animation} className={layoutClassName}>
      <ContentContainerComponent>
        {children}

        {Boolean(credits?.length) && (
          <ContainerMd>
            <Credits
              credits={credits?.map((item, i) => <WoodwingContentItem key={`credit-${i}`} styleId='none' {...{ item }} />)}
              {...{ contentIsCentered }}
            />
          </ContainerMd>
        )}

        {Boolean(tags?.length) && onActiveTagModalChange && (
          <ContainerMd>
            <TagsArticlePage isActive={tagModalIsActive} onActiveChange={onActiveTagModalChange} {...{ tags, contentIsCentered, isCanonical }} />
          </ContainerMd>
        )}
      </ContentContainerComponent>
    </animated.section>
  )
}

function ArticlePageContentDefault({
  sectionRef,
  animation,
  isCanonical,
  contentItems,
  credits,
  tagModalIsActive,
  onActiveTagModalChange,
  tags =  [],
  contentIsCentered = false,
  layoutClassName = undefined
}) {
  return (
    <ArticlePageContent {...{ sectionRef, animation, isCanonical, credits, tags, tagModalIsActive, onActiveTagModalChange, contentIsCentered, layoutClassName }}>
      <WoodwingContent styleId='page' layoutStyleId='page' {...{ contentItems }} />
    </ArticlePageContent>
  )
}

function ArticlePageContentGedicht({ doc, sectionRef, animation, layoutClassName = undefined }) {
  const { content } = doc
  const { metadata: { credits } } = content
  const { poem, author, description, position, audio } = content

  return (
    <ArticlePageContent contentIsCentered {...{ sectionRef, animation, credits, layoutClassName }}>
      <div className={styles.componentContentGedicht}>
        {audio && // TODO: Erik - Move this check to normalization
          <div className={styles.audioLayout}>
            <AudioPlayerArticlePage src={audio.src} />
          </div>
        }

        <WoodwingContentItem styleId='page' item={poem} />

        <div className={cx(styles.nameAndPosition, styles.nameAndPositionLayout)}>
          {author && <WoodwingContentItem item={author} {...{ layoutClassName }} />} {/* TODO: Erik - Move this check to normalization */}
          {position && <WoodwingContentItem item={position} {...{ layoutClassName }} />} {/* TODO: Erik - Move this check to normalization */}
        </div>

        {description && (
          <ContainerMd layoutClassName={styles.kaderLayout}>
            <WoodwingKader contentItems={description} styleId='page' />
          </ContainerMd>
        )}
      </div>
    </ArticlePageContent>
  )
}

function ArticlePageContentSnackables({ doc, animation, sectionRef, layoutClassName = undefined }) {
  const { __ } = useTranslate()
  const { slug, content: { metadata: { credits }, articles }, metadata: { tags } } = doc

  return (
    <ArticlePageContent {...{ animation, sectionRef, tags, credits, layoutClassName }}>
      <SnackableArticles {...{ articles }} />

      {slug === 'ledenaanbiedingen' && (
        <ContainerMd>
          <div className={styles.buttonContainer}>
            <ButtonLinkSecondary
              href='https://www.rabobank.nl/leden/ledenaanbiedingen'
              dataX='link-to-ledenaanbiedingen'
              label={__`check-all-member-offers`}
              icon={chrevronRight}
              layoutClassName={styles.buttonLayout}
            />
          </div>
        </ContainerMd>
      )}
    </ArticlePageContent>
  )
}

function SnackableArticles({ articles }) {
  return articles.map((article, i) => (
    <ContainerMd key={i}>
      <SnackableArticle {...{ article }} />
    </ContainerMd>
  ))
}

function SnackableArticle({ article }) {
  return (
    <div className={styles.componentSnackableArticle}>
      <div>
        <ImageCover image={article.content.hero.image} aspectRatio={2 / 3} />
      </div>
      <div className={styles.articleContentContainer}>
        <div className={styles.contentTitleContainer}>
          <HeadingGroup h={2} title={article.content.hero.title} />
        </div>
        <WoodwingContent layoutStyleId='snackable' styleId='snackable' contentItems={article.content.items} />
      </div>
    </div>
  )
}

function findArticleIndex(article, articles) {
  const { slug } = article.metadata.rubric

  return articles.findIndex(x => x.metadata.rubric.slug === slug)
}

function useReferredLink() {
  const { __ } = useTranslate()
  const language = useLanguage()

  const { host } = useClientConfig()
  const linkInfoRef = React.useRef(undefined)

  React.useEffect(
    () => {
      if (document.referrer.includes(host)) {
        linkInfoRef.current = {
          label: __`go-back`,
          handleReferredLinkClick: () => { window.history.back() }
        }
      } else {
        linkInfoRef.current = {
          label: __`go-back-home`,
          handleReferredLinkClick: () => { window.location.href = routeMap.app.home({ language }) }
        }
      }
    },
    [__, language, host]
  )

  const { label, handleReferredLinkClick } = linkInfoRef.current || {}

  return { label, handleReferredLinkClick }
}
