import { animated } from '@react-spring/web'
import { useScrollProgression, triggers } from '@kaliber/scroll-progression'

import { ContainerMd } from '/features/buildingBlocks/Container'
import { HeadingGroup } from '/features/buildingBlocks/HeadingGroup'
import { Icon } from '/features/buildingBlocks/Icon'
import { MetaTagWrapper, MetaTag, MetaTagButton } from '/features/article/buildingBlocks/MetaTag'
import { ArticleHeroSingleSnackable, ArticleHeroSnackables } from '/features/article/buildingBlocks/ArticleHero'

import styles from './ArticleIntro.css'

import arrowDown from '/images/icons/arrow-down.raw.svg'

export function ArticleIntroDefault({
  hero,
  rubric,
  tags,
  isNextArticleIntro = false,
  onActiveTagModalChange = undefined,
  tagsClickable = false,
  url = undefined,
  style = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroBase
      className={cx(styles.componentDefault, layoutClassName)}
      {...{
        hero,
        url,
        rubric,
        tags,
        onActiveTagModalChange,
        tagsClickable,
        style,
        isNextArticleIntro
      }}
    />
  )
}

export function ArticleIntroDefaultBlack({
  hero,
  rubric,
  isNextArticleIntro = false,
  url = undefined,
  style = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroBase className={cx(styles.componentDefaultBlack, layoutClassName)} {...{ hero, url, rubric, style, isNextArticleIntro }} />
  )
}

export function ArticleIntroGedicht({
  hero,
  rubric,
  isNextArticleIntro = false,
  url = undefined,
  style = undefined,
  layoutClassName = undefined
}) {
  return (
    <ArticleIntroBase className={cx(styles.componentGedicht, layoutClassName)} {...{ hero, url, rubric, style, isNextArticleIntro }} />
  )
}

export function ArticleIntroSingleSnackable({
  hero,
  rubric,
  isNextArticleIntro = false,
  style = undefined,
  layoutClassName = undefined
}) {
  const { title, image } = hero

  return (
    <div className={cx(styles.componentSingleSnackable, layoutClassName)}>
      <div className={cx(styles.introWrapper, isNextArticleIntro && styles.isNextArticleIntro)}>
        <ArticleHeroSingleSnackable showShareButton heroImage={image} {...{  title, rubric }} />
      </div>
      {style && <animated.span className={cx(styles.contentCardBorder, styles.contentCardBorderLayout)} style={{ translateY: '1px', ...style }} />}
    </div>
  )
}


export function ArticleIntroSnackables({
  hero,
  rubric,
  articles,
  isNextArticleIntro = false,
  style = undefined,
  layoutClassName = undefined
}) {
  const { title, image } = hero

  return (
    <div className={cx(styles.componentSnackables, layoutClassName)}>
      <div className={cx(styles.introWrapper, isNextArticleIntro && styles.isNextArticleIntro)}>
        <ArticleHeroSnackables showShareButton heroImage={image} {...{  title, rubric, articles }} />
      </div>
      {style && <animated.span className={cx(styles.contentCardBorder, styles.contentCardBorderLayout)} style={{ translateY: '1px', ...style }} />}
    </div>
  )
}

function ArticleIntroBase({
  hero,
  rubric,
  style,
  className,
  isNextArticleIntro,
  tags = undefined,
  onActiveTagModalChange = undefined,
  tagsClickable = false,
  containerComponent = undefined,
  url = undefined
}) {
  const [scrollProgression, setScrollProgression] = React.useState(0)

  const { title } = hero

  const trackedElementRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.top() },
    end: { element: triggers.bottom(), scrollParent: triggers.bottom(-200) },
    onChange(progression) { setScrollProgression(progression) }
  })

  return (
    <div ref={trackedElementRef} className={cx(styles.componentBase, className)}>
      <div className={cx(styles.introWrapper, isNextArticleIntro && styles.isNextArticleIntro)}>
        <ArticleIntroHeading layoutClassName={styles.headingLayout} {...{ containerComponent, title, rubric, tags, onActiveTagModalChange, tagsClickable, scrollProgression, url }} />
      </div>
      {style && <animated.span className={styles.contentCardBorder} style={{ translateY: '1px', ...style }} />}
    </div>
  )
}

function ArticleIntroHeading({
  containerComponent,
  title,
  rubric,
  scrollProgression,
  isNextArticleIntro = false,
  url = undefined,
  tags = undefined,
  tagsClickable = undefined,
  onActiveTagModalChange = undefined,
  layoutClassName = undefined
}) {
  const Container = containerComponent || ContainerMd

  const opacity = isNextArticleIntro
    ? null
    : 1 - (easeIn(scrollProgression) * 3)

  return (
    <div style={{ opacity }} className={cx(styles.componentHeading, layoutClassName)}>
      <Container>
        <div className={styles.headerLayoutWrapper}>
          <HeadingGroup h={1} subtitle={rubric} layoutClassName={styles.headingGroupLayout} {...{ title }} />

          {tags && (
            <MetaTagWrapper>
              {tags.map((label, i) => {
                if (tagsClickable) return <MetaTagButton key={i} onActiveChange={onActiveTagModalChange} {...{ label }} />
                else return <MetaTag key={i} {...{ label }} />
              })}
            </MetaTagWrapper>
          )}

          {url && <ArrowIcon layoutClassName={styles.arrowIconLayout} />}
        </div>
      </Container>
    </div>
  )
}

function ArrowIcon({ layoutClassName = undefined }) {
  return (
    <div className={layoutClassName}>
      <Icon icon={arrowDown} layoutClassName={styles.arrowLayout} />
    </div>
  )
}

function easeIn(x) {
  return x * x
}
